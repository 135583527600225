import React from 'react';

interface PrevIconProps {
  pathColor: string;
  dataTestId?: string;
}

export function PrevIcon(props: PrevIconProps) {
  const { pathColor, dataTestId = 'test-prev-icon' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      data-testid={dataTestId}
    >
      <path
        d="M22.425 24.0003L29.6 31.1753L26.3334 34.5003L15.8334 24.0003L26.3334 13.5003L29.6 16.8253L22.425 24.0003ZM24 47.3337C27.2278 47.3337 30.2612 46.7212 33.1 45.4962C35.9389 44.2712 38.4084 42.6087 40.5084 40.5087C42.6084 38.4087 44.2709 35.9392 45.4959 33.1003C46.7209 30.2614 47.3334 27.2281 47.3334 24.0003C47.3334 20.7725 46.7209 17.7392 45.4959 14.9003C44.2709 12.0614 42.6084 9.59199 40.5084 7.49199C38.4084 5.39199 35.9389 3.72949 33.1 2.50449C30.2612 1.27949 27.2278 0.666992 24 0.666992C20.7723 0.666992 17.7389 1.27949 14.9 2.50449C12.0611 3.72949 9.59171 5.39199 7.49171 7.49199C5.3917 9.59199 3.72921 12.0614 2.50421 14.9003C1.27921 17.7392 0.666706 20.7725 0.666706 24.0003C0.666706 27.2281 1.27921 30.2614 2.50421 33.1003C3.72921 35.9392 5.3917 38.4087 7.49171 40.5087C9.59171 42.6087 12.0611 44.2712 14.9 45.4962C17.7389 46.7212 20.7723 47.3337 24 47.3337Z"
        fill={pathColor}
      />
    </svg>
  );
}
