import React, { FC } from 'react';
import { Button, Text } from 'atomize';

import { useCountdownTimer } from '../../../hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useStyletron } from 'styletron-react';
import * as styles from './styles.module.css';

interface TryAgainTimerProps {
  delay: number;
  onResendCode: () => void;
}

export const TryAgainButton: FC<TryAgainTimerProps> = (props) => {
  const { delay, onResendCode } = props;
  const { t } = useTranslation();
  const { isTimerRunning, minutes, seconds, startTimer } = useCountdownTimer(delay);
  const [css] = useStyletron();

  const getCode = () => {
    startTimer();
    onResendCode();
  };

  const titleClassName = css({ color: isTimerRunning ? 'rgba(255, 255, 255, 0.5)' : 'white' });

  return (
    <Button
      onClick={getCode}
      w="max-content"
      h="auto"
      textColor="white"
      p="12px"
      rounded="12px"
      textWeight={500}
      type="button"
      disabled={isTimerRunning}
      className={styles.buttonBg}
    >
      <Text tag="p" textColor={'white'} textWeight={500}>
        <Text tag="span" className={titleClassName}>
          {t('getCodeAgain')}
        </Text>
        {isTimerRunning && (
          <Text tag="span" textColor="white">
            &nbsp;{minutes}:{seconds}
          </Text>
        )}
        &nbsp;
      </Text>
    </Button>
  );
};
