import { Input } from 'atomize';
import React, { forwardRef } from 'react';

export interface CellInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  status?: 'default' | 'error' | 'success';
  atomizeStyles?: Record<string, string>;
}

export const CellInput = forwardRef((props: CellInputProps, ref: any) => {
  const { status, atomizeStyles, ...inputProps } = props;
  const borderColor = borderColors.get(props.status ?? 'default');

  return (
    <Input
      ref={ref}
      bg="bg1"
      rounded="s"
      p="16px"
      h="61px"
      outline="none"
      border="1px solid"
      borderColor={borderColor}
      focusBorderColor={status === 'error' ? 'errorRed' : 'white'}
      textColor="white"
      textSize="24px"
      textWeight={500}
      type="text"
      textAlign="center"
      maxLength={1}
      {...inputProps}
      {...atomizeStyles}
    />
  );
});

const borderColors = new Map<Exclude<CellInputProps['status'], undefined>, string>([
  ['default', 'transparent'],
  ['error', 'errorRed'],
  ['success', 'green'],
]);
