export interface Feedback {
  reason: RejectionReason;
  message: string;
}

export enum RejectionReason {
  INVALID_DRIVER_LICENSE = 'INVALID_DRIVER_LICENSE',
  INVALID_REGISTRATION = 'INVALID_REGISTRATION',
  EXPIRED_DRIVER_LICENSE = 'EXPIRED_DRIVER_LICENSE',
  EXPIRED_REGISTRATION = 'EXPIRED_REGISTRATION',
  MISSING_DOCUMENT = 'MISSING_DOCUMENT',
  POOR_PHOTO_QUALITY = 'POOR_PHOTO_QUALITY',
  MISMATCHED_INFO = 'MISMATCHED_INFO',
  UNREADABLE_DOCUMENT = 'UNREADABLE_DOCUMENT',
  FAKE_DOCUMENT_SUSPECTED = 'FAKE_DOCUMENT_SUSPECTED',
  DUPLICATE_ACCOUNT = 'DUPLICATE_ACCOUNT',
  INCOMPLETE_DOCUMENT = 'INCOMPLETE_DOCUMENT',
  OTHER = 'OTHER',
}
