import React, { FC } from 'react';
import { Div } from 'atomize';
import { Link, SiteLogo } from '@/shared/ui';
import { UserProfileMenu } from './user-profile-menu';
import { UserRole } from '@/shared/user-roles';
import { styled } from 'styletron-react';
import { useTranslation } from 'react-i18next';

export interface UserInfo {
  fullName: string;
  role: UserRole;
}

export interface LinkItem {
  path: string;
  title: string;
}

interface AppHeaderProps {
  user: UserInfo;
  links: LinkItem[];
  openDriversVerification: () => void;
}

export const AppHeader: FC<AppHeaderProps> = (props) => {
  const { user, links, openDriversVerification } = props;
  const { t } = useTranslation();

  return (
    <Div tag="header" d="flex" justify="space-between" align="center">
      <Link to={'/admin/dashboard'}>
        <SiteLogo />
      </Link>
      <Div d="flex" align="center" style={{ gap: '16px' }}>
        {links.map(({ path, title }) => (
          <Link key={path} to={path}>
            {title}
          </Link>
        ))}
      </Div>
      <FlexRow>
        <OpenDriversVerificationButton onClick={openDriversVerification}>
          {t('appHeader')}
        </OpenDriversVerificationButton>
        <UserProfileMenu {...user} />
      </FlexRow>
    </Div>
  );
};

const OpenDriversVerificationButton = styled('button', {
  padding: '8px 14px',
  backgroundColor: '#F39C12',
  borderRadius: '10px',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
});

const FlexRow = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
  gap: '10px',
});
