import { CognitoIdToken, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

import { SessionUser } from '@/app/clients';

export const config = {
  APPSYNC_ENDPOINT: process.env.GATSBY_APPSYNC_ENDPOINT!,
  MANGO_USER_ID: process.env.GATSBY_MANGO_USER_ID!,
  COGNITO_CLIENT_ID: process.env.GATSBY_COGNITO_CLIENT_ID!,
  COGNITO_IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID!,
  COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID!,
  REGION: process.env.GATSBY_REGION!,
};

export const COGNITO_USER_POOL_DATA = {
  UserPoolId: config.COGNITO_USER_POOL_ID,
  ClientId: config.COGNITO_CLIENT_ID,
};

export function getSessionUser(session: CognitoUserSession) {
  const jwtToken = session.getIdToken().getJwtToken();
  const idToken = new CognitoIdToken({ IdToken: jwtToken });

  return idToken.payload as SessionUser;
}

export async function getCognitoUserSession(userPool = COGNITO_USER_POOL_DATA): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUserPool = new CognitoUserPool(userPool);
    const cognitoUser = cognitoUserPool.getCurrentUser();

    cognitoUser?.getSession((...ps: [Error, null] | [null, CognitoUserSession]) => {
      const [err, session] = ps;

      if (err) {
        return reject(err);
      }

      resolve(session);
    });
  });
}

export function cognitoSignOut() {
  const cognitoUserPool = new CognitoUserPool(COGNITO_USER_POOL_DATA);
  const cognitoUser = cognitoUserPool.getCurrentUser();

  cognitoUser?.signOut();
}
