import React from 'react';
import { Provider } from 'react-redux';

import { setAnonymousClient } from '@/app/clients';
import { createStore } from '@/app/store';

const store = createStore();

export function wrapRootElement(props) {
  return <Provider store={store}>{props.element}</Provider>;
}

export const onClientEntry = async () => {
  setAnonymousClient();
};
