import { UnverifiedDriver } from '@/entities/unverified-driver';

export const unverifiedDrivers: UnverifiedDriver[] = [
  {
    id: '1',
    fullName: 'John Doe',
    avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    inn: '123456789012',
    verificationSentDate: '2023-08-15',
    phone: '+1-555-1234',
    issuingAuthority: 'Department of Transport',
    issueDate: '2018-05-20',
    carModel: 'Toyota Camry',
    carColor: 'Black',
    licensePlate: 'XYZ 1234',
    manufactureYear: 2017,
    licenseFrontPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
    licenseBackPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
    techPassportFrontPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
    techPassportBackPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
  },
  {
    id: '2',
    fullName: 'Jane Smith',
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
    inn: '234567890123',
    verificationSentDate: '2023-09-10',
    phone: '+1-555-5678',
    issuingAuthority: 'DMV',
    issueDate: '2020-03-11',
    carModel: 'Honda Accord',
    carColor: 'White',
    licensePlate: 'ABC 5678',
    manufactureYear: 2019,
    licenseFrontPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
    licenseBackPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
    techPassportFrontPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
    techPassportBackPhoto:
      'https://77fe644c572ff1ba8a08-aa3fcb8dba820dc6b4fabb3e45b3ad4d.ssl.cf1.rackcdn.com/images/media/74404/article_380/drivers%20license.jpg?1552944085',
  },
  {
    id: '3',
    fullName: 'Alex Johnson',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    inn: '345678901234',
    verificationSentDate: '2023-10-05',
    phone: '+1-555-9101',
    issuingAuthority: 'City Licensing Office',
    issueDate: '2021-08-29',
    carModel: 'Ford Focus',
    carColor: 'Blue',
    licensePlate: 'DEF 9101',
    manufactureYear: 2018,
    licenseFrontPhoto: 'https://example.com/license_front_3.jpg',
    licenseBackPhoto: 'https://example.com/license_back_3.jpg',
    techPassportFrontPhoto: 'https://example.com/tech_passport_front_3.jpg',
    techPassportBackPhoto: 'https://example.com/tech_passport_back_3.jpg',
  },
  {
    id: '4',
    fullName: 'Emily Davis',
    avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
    inn: '456789012345',
    verificationSentDate: '2023-07-25',
    phone: '+1-555-1122',
    issuingAuthority: 'State Vehicle Authority',
    issueDate: '2017-12-18',
    carModel: 'BMW X5',
    carColor: 'Silver',
    licensePlate: 'GHI 1122',
    manufactureYear: 2016,
    licenseFrontPhoto: 'https://example.com/license_front_4.jpg',
    licenseBackPhoto: 'https://example.com/license_back_4.jpg',
    techPassportFrontPhoto: 'https://example.com/tech_passport_front_4.jpg',
    techPassportBackPhoto: 'https://example.com/tech_passport_back_4.jpg',
  },
  {
    id: '5',
    fullName: 'Michael Brown',
    avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
    inn: '567890123456',
    verificationSentDate: '2023-06-14',
    phone: '+1-555-3344',
    issuingAuthority: 'Transport Services Agency',
    issueDate: '2019-02-07',
    carModel: 'Audi A4',
    carColor: 'Gray',
    licensePlate: 'JKL 3344',
    manufactureYear: 2020,
    licenseFrontPhoto: 'https://example.com/license_front_5.jpg',
    licenseBackPhoto: 'https://example.com/license_back_5.jpg',
    techPassportFrontPhoto: 'https://example.com/tech_passport_front_5.jpg',
    techPassportBackPhoto: 'https://example.com/tech_passport_back_5.jpg',
  },
];
