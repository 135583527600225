import { UserRole } from './user-roles';

export const routePaths = {
  home: '/',
  drivers: '/drivers/',
  login: '/login/',
  dashboard: '/dashboard/',
  orders: '/orders/',
  users: '/users/',
  verifyCode: '/login/verify-code/',
};

export function getDefaultPath(userRole: UserRole) {
  switch (userRole) {
    case UserRole.ADMIN:
      return routePaths.drivers;
    case UserRole.OPERATOR:
      return routePaths.home;
    case UserRole.USER:
      return routePaths.home;

    default:
      throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
  }
}

export const pagePermissions = {
  dashboard: new Set([UserRole.ADMIN, UserRole.OPERATOR]),
  drivers: new Set([UserRole.ADMIN, UserRole.OPERATOR]),
  users: new Set([UserRole.ADMIN, UserRole.OPERATOR]),
} as const;

export type PageKey = keyof typeof pagePermissions;
