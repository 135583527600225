import { Div, Text } from 'atomize';
import React, {
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { styled } from 'styletron-react';
import { TryAgainButton, CellInput, SiteLogo } from '@/shared/ui';
import { useAppSelector } from '@/app/store';
import {
  MutationErrorResponse,
  useRequestVerificationCodeMutation,
  useSendVerificationCodeMutation,
} from '@/features/login';

interface VerifyLoginCodeFormProps {
  onSuccess: () => void;
}

export function VerifyLoginCodeForm(props: VerifyLoginCodeFormProps) {
  const userName = useAppSelector((state) => state.login.userName ?? '');
  const [verifyCode, verifyCodeState] = useSendVerificationCodeMutation();
  const [requestCode, requestCodeState] = useRequestVerificationCodeMutation();
  const [code, setCode] = useState('');
  const { t } = useTranslation();
  const [inputError, setInputError] = useState(false);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const formRef = useRef<HTMLFormElement>(null);
  const lastFocusedInputRef = useRef<number>(0);
  /**
   * We need ref keys for the following reasons:
   * - to have unique key for rendering an array (and not rely on index param in Array#map)
   * - to force re-render with new defaultValue (needed when we reset the code input)
   */
  const [inputRefKeys, setInputRefKeys] = useState(() => inputRefs.map((_, i) => i));

  const updateInputRefKeys = () => {
    // We need to update all keys to new values
    setInputRefKeys((keys) => keys.map((x, _, src) => x + src.length));
  };

  useEffect(() => {
    if (code.length === 6 && formRef.current) {
      formRef.current.requestSubmit();
    }
  }, [code]);

  const isLoading = verifyCodeState.isLoading || requestCodeState.isLoading;

  useEffect(() => {
    if (!isLoading && inputRefs[lastFocusedInputRef.current]) {
      inputRefs[lastFocusedInputRef.current].current.focus();
    }
  }, [isLoading, lastFocusedInputRef]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];
    const newCode = [...code];

    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
    } else {
      newCode[index] = input.value;
    }

    setCode(newCode.join(''));
    setInputError(false);
    lastFocusedInputRef.current = index;
    input.select();

    if (input.value === '') {
      if (previousInput) {
        previousInput.current.focus();
      }
    } else if (nextInput) {
      nextInput.current.select();
    }
  };

  const handleFocusFor = (index: number) => (e: FocusEvent<HTMLInputElement>) => {
    lastFocusedInputRef.current = index;
    e.target.select();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    if ((e.key === 'Backspace' || e.key === 'Delete') && input.value === '') {
      e.preventDefault();
      setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData('text');
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
      lastFocusedInputRef.current = 5;
    }
  };

  const onCodeResend = () => {
    requestCode({ userName });
  };

  const requestError = requestCodeState.isError
    ? t((requestCodeState as MutationErrorResponse).error.code)
    : verifyCodeState.isError
      ? t((verifyCodeState as MutationErrorResponse).error.code)
      : undefined;
  const error = inputError ? t('correctCode') : requestError;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (code.length < 6) {
      setInputError(true);

      return;
    }

    verifyCode({ userName, verificationCode: code })
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        setCode('');
        updateInputRefKeys();
      });
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <SiteLogo />
      <Text
        tag="p"
        textColor="white"
        textAlign="center"
        textSize="20px"
        m={{ x: 'auto', y: '15px' }}
        style={{ minWidth: 337, maxWidth: 337, lineHeight: '28px' }}
      >
        {t('enterConfirmCode')}{' '}
        <Text tag="span" textColor="secondary">
          {userName.slice(0, 4)}&nbsp;
        </Text>
        {userName.slice(4).replace(/(.{3})/g, '$1 ')}
      </Text>
      <Div m={{ b: '20px' }}>
        <Div d="flex" maxW="100%" m={{ t: '10px' }} style={{ gap: 10 }}>
          {inputRefKeys.map((key, index) => (
            <CellInput
              key={key}
              ref={inputRefs[index]}
              defaultValue={code[index]}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleInput(event, index)}
              status={error ? 'error' : 'default'}
              autoFocus={index === 0}
              onFocus={handleFocusFor(index)}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, index)}
              onPaste={handlePaste}
              disabled={isLoading}
            />
          ))}
        </Div>
        {error && (
          <Text tag="p" textColor="errorRed" m={{ t: '5px' }}>
            {error}
          </Text>
        )}
      </Div>

      <TryAgainButton delay={60} onResendCode={onCodeResend} />
      <button hidden type="submit" />
    </Form>
  );
}

const Form = styled('form', {
  width: '454px',
  backgroundColor: '#101010',
  borderRadius: '20px',
  padding: '25px',
  display: 'flex',
  flexDirection: 'column',
});
