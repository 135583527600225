import { Div, Text } from 'atomize';
import React from 'react';

import { Logo } from '@/shared/assets/svg';

export const SiteLogo = () => {
  return (
    <Div d="flex" align="center" justify="center" style={{ gap: 6 }}>
      <Logo />
      <Text tag="h4" textSize="20px" textWeight={600} textColor="white">
        mango
        <Text
          tag="span"
          style={{
            width: 100,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maskImage: 'linear-gradient(to right, black -38%, transparent 133%)',
            marginLeft: 8,
          }}
        >
          admin
        </Text>
      </Text>
    </Div>
  );
};
