import { SessionUser } from '@/app/clients';
import { UserRole, extractRoleFromARN } from '@/shared/user-roles';

export function getUserRole(currentUser: SessionUser) {
  // TODO: check if actual role is included in user payload
  return UserRole.USER;
  if (!currentUser?.roles) {
    if (currentUser?.hasOwnProperty('cognito:preferred_role')) {
      currentUser.roles = extractRoleFromARN(currentUser['cognito:preferred_role']);
    }
  }

  return currentUser?.roles;
}
