import React, { FC } from 'react';
import { styled } from 'styletron-react';

interface TableContentHeaderProps {
  title: string;
  subTitle?: string;
  actions?: JSX.Element;
  dataTestId?: string;
}

export const TableContentHeader: FC<TableContentHeaderProps> = (props) => {
  const { title, subTitle, actions, dataTestId } = props;
  return (
    <Header data-testid={dataTestId}>
      <Title>
        {title} {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </Title>
      {actions}
    </Header>
  );
};

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  width: 'fit-content',
});

const Title = styled('h1', {
  color: 'white',
  fontSize: '32px',
  fontWeight: '400',
});

const SubTitle = styled('span', {
  color: '#5a5a5a',
});
