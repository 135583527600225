import { Button, Icon } from 'atomize';
import React, { FormEvent, MouseEvent } from 'react';
import { ReactNode } from 'react';
import { FilterIcon } from '@/shared/assets/svg';
import { styled } from 'styletron-react';
import { styleVars } from '@/shared/default-theme';
import { useTranslation } from 'react-i18next';

export interface FilterField {
  label: string;
  field: ReactNode;
}

interface FilterDialogProps {
  isOpen: boolean;
  filters: FilterField[];
  activeFilters: number;
  onClose: () => void;
  onClear: () => void;
  onToggle: () => void;
}

export function FilterDialog(props: FilterDialogProps) {
  const { isOpen, activeFilters, filters, onToggle, onClear } = props;
  const { t } = useTranslation('');

  const activeFiltersColor = activeFilters ? 'white' : styleVars.secondaryText;

  const handleFilterClear = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    onClear();
  };
  return (
    <DialogWrapper>
      <DialogToggleButton
        prefix={<FilterIcon pathFill={activeFiltersColor} />}
        suffix={
          !!activeFilters && (
            <Icon
              d="block"
              name="Cross"
              color={activeFiltersColor}
              size="20px"
              cursor="pointer"
              m={{ l: '10px' }}
              onClick={handleFilterClear}
              data-testid="test-cross-icon"
            />
          )
        }
        onClick={onToggle}
        type="button"
        $activeFiltersColor={activeFiltersColor}
        $activeFilters={activeFilters}
        data-testid="test-dialog-toggle-button"
      >
        {t('filterDialog_title')} {activeFilters ? `: ${activeFilters}` : ''}
      </DialogToggleButton>
      <Dialog $isOpen={isOpen}>
        <FilterForm onSubmit={(event: FormEvent) => event.stopPropagation()}>
          <FilterFormHeader>
            <FilterFormTitle>{t('filterDialog_title')}</FilterFormTitle>
            <ClearButton
              onClick={onClear}
              type="button"
              disabled={!activeFilters}
              data-testid="test-filter-clear-button"
            >
              {t('clear')}
            </ClearButton>
          </FilterFormHeader>
          <FilterFormBody>
            {filters.map((filter) => (
              <FilterField key={filter.label}>
                <FilterLabel>{filter.label}</FilterLabel>
                {filter.field}
              </FilterField>
            ))}
          </FilterFormBody>
        </FilterForm>
      </Dialog>
      <Overlay $isOpen={isOpen} onClick={onToggle} data-testid="test-dialog-overlay" />
    </DialogWrapper>
  );
}

const DialogToggleButton = styled(Button, (props: { $activeFiltersColor: string; $activeFilters: number }) => ({
  backgroundColor: styleVars.bg4,
  color: props.$activeFiltersColor,
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  fontWeight: props.$activeFilters ? 600 : 400,
}));

const Overlay = styled('div', (props: { $isOpen: boolean }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: props.$isOpen ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
  pointerEvents: props.$isOpen ? 'auto' : 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const DialogWrapper = styled('div', {
  position: 'relative',
  display: 'inline-block',
});

const Dialog = styled('div', (props: { $isOpen: boolean }) => ({
  display: props.$isOpen ? 'block' : 'none',
  maxHeight: props.$isOpen ? 'auto' : '0',
  overflow: 'hidden',
  marginTop: '10px',
  transition: 'max-height 0.3s ease-in-out',
  backgroundColor: styleVars.bg4,
  borderRadius: '10px',

  boxShadow: `
    0px 5px 5px 0px #00000033, 
    0px 3px 14px 0px #0000001F, 
    0px 8px 10px 0px #00000024
  `,

  width: '555px',
  position: 'absolute',
  right: 0,
  zIndex: 999,
}));

const FilterForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const FilterFormHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderBottom: `1px solid ${styleVars.initialInputBorderColor}`,
});

const FilterFormTitle = styled('p', {
  fontWeight: '600',
  fontSize: '14px',
  color: styleVars.secondaryText,
});

const FilterFormBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '0 16px 16px',
});

const FilterField = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '5px',
});

const FilterLabel = styled('label', {
  maxWidth: '154px',
  minWidth: 'max-content',
  width: '100%',

  backgroundColor: styleVars.bg4,
  color: styleVars.secondaryText,
  fontSize: '14px',
});

const ClearButton = styled(Button, {
  background: 'none',
  border: 'none',
  outline: 'none',
  color: 'white',
  padding: '0px !important',
  height: '16px',
  width: 'auto',

  ':disabled': {
    color: styleVars.inactive,
    background: 'none',
    width: 'auto',
    padding: 0,
  },
});
