import React, { forwardRef } from 'react';
import { Input } from 'atomize';

export interface StyledInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  status?: 'default' | 'success' | 'error';
  atomizeStyles?: Record<string, string>;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

export const StyledInput = forwardRef((props: StyledInputProps, ref: any) => {
  const { status = 'default', atomizeStyles, ...inputProps } = props;
  const borderColor = borderColors.get(status);

  return (
    <Input
      ref={ref}
      bg="#FFFFFF0D"
      border="1px solid"
      borderColor={borderColor}
      focusBorderColor={borderColor}
      rounded="8px"
      h="54px"
      textSize="16px"
      textColor="white"
      {...inputProps}
      {...atomizeStyles}
    />
  );
});

const borderColors = new Map<Exclude<StyledInputProps['status'], undefined>, string>([
  ['default', 'defaultInputBorder'],
  ['error', 'errorRed'],
  ['success', 'green'],
]);
