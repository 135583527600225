const locales = {
  EN: 'en',
  KY: 'ky',
  RU: 'ru',
} as const;

export const l10n = {
  locales,
  defaultLocale: locales.RU,
  supportedLocales: Object.values(locales),
};
