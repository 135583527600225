import { useState, useEffect } from 'react';

export const useCountdownTimer = (initialDelay: number) => {
  const [timeLeft, setTimeLeft] = useState(initialDelay);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 1) setIsRunning(false);

          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  const startTimer = () => {
    setIsRunning(true);
    setTimeLeft(initialDelay);
  };

  return {
    minutes: Math.floor(timeLeft / 60),
    seconds: String(timeLeft % 60).padStart(2, '0'),
    isTimerRunning: isRunning,
    startTimer,
  };
};
