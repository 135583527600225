import { Div, StyleReset, ThemeProvider } from 'atomize';
import React, { ReactNode } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { defaultTheme } from '@/shared/default-theme';
import '@/app/styles/global.css';
import { l10n } from '../../../../locales';
import { ApolloWrapper } from '@/app/apollo-wrapper';

const theme = {
  ...defaultTheme,
};

export function UnauthorizedLayout({ children }: { children: ReactNode }) {
  const siteMetadata = useStaticQuery(query)?.site?.siteMetadata ?? {};
  const title = siteMetadata.title ?? 'Mango';

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: l10n.defaultLocale,
        }}
        title={title}
      >
        <meta charSet="utf-8" />
      </Helmet>
      <StyleReset />
      <main id="main" style={{ minHeight: '100vh' }}>
        <ApolloWrapper>
          <Div d="flex" justify="center" align="center" h="100vh">
            {children}
          </Div>
        </ApolloWrapper>
      </main>
    </ThemeProvider>
  );
}

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
