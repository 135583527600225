import React, { useEffect, useState } from 'react';
import { UnverifiedDriver, UnverifiedDriverView } from '@/entities/unverified-driver';
import { styled, useStyletron } from 'styletron-react';
import { styleVars } from '@/shared/default-theme';
import { Modal, SelectOption, CustomSlider } from '@/shared/ui';
import { RejectDriverForm, RejectionReason, Feedback } from '@/features/reject-driver';
import { unverifiedDrivers } from './mock-data';
import { useTranslation } from 'react-i18next';

export function UnverifiedDrivers() {
  const { t } = useTranslation();
  const [css] = useStyletron();

  const [drivers, setDrivers] = useState<UnverifiedDriver[]>(unverifiedDrivers);
  const [rejectDriverFormIsOpen, setRejectDriverFormIsOpen] = useState(false);
  const [driverId, setDriverId] = useState('');

  useEffect(() => {
    setDrivers(unverifiedDrivers);
  }, []);

  const onApprove = (driverId: string) => {
    setDrivers((prev) => prev.filter((driver) => driver.id !== driverId));
  };

  const onBlock = (driverId: string) => {
    setDrivers((prev) => prev.filter((driver) => driver.id !== driverId));
  };

  const onReject = (driverId: string) => {
    setDrivers((prev) => prev.filter((driver) => driver.id !== driverId));
  };

  const openRejectionForm = (driverId: string) => {
    setDriverId(driverId);
    setRejectDriverFormIsOpen(true);
  };

  const onRejectDriver = (feedback: Feedback) => {
    console.log('feedback', feedback);
    setRejectDriverFormIsOpen(false);
    onReject(driverId);
  };

  const renderUnverifiedDrivers = () =>
    drivers.map((driver) => (
      <UnverifiedDriverView driverInfo={driver} onApprove={onApprove} onReject={openRejectionForm} onBlock={onBlock} />
    ));

  const reasons: SelectOption<RejectionReason>[] = Object.values(RejectionReason).map((reason) => ({
    label: t(reason),
    value: reason,
  }));

  return (
    <>
      <Modal isOpen={rejectDriverFormIsOpen} onClose={() => setRejectDriverFormIsOpen(false)}>
        <RejectDriverForm
          onCancel={() => setRejectDriverFormIsOpen(false)}
          onSubmit={(feedback) => onRejectDriver(feedback)}
          reasonOptions={reasons}
          title={t('selectReason')}
          loading={false}
        />
      </Modal>
      {drivers.length > 0 ? (
        <CustomSlider className={css({ maxWidth: '926px', maxHeight: '600px', zIndex: 99 })}>
          {renderUnverifiedDrivers()}
        </CustomSlider>
      ) : (
        <NoData>{t('UnverifiedDrivers')}</NoData>
      )}
    </>
  );
}

const NoData = styled('div', {
  textAlign: 'center',
  color: 'white',

  backgroundColor: styleVars.bg4,
  padding: '24px',
  borderRadius: '10px',
});
