import { Store, combineSlices, configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { authnApiService, loginSlice } from '@/features/login';

const rootEpic = combineEpics();

const reducer = combineSlices(loginSlice, authnApiService);

export type RootSSRState = ReturnType<typeof reducer>;

let store: Store<RootSSRState>;

export function createSSRStore(): Store {
  const epicMiddleware = createEpicMiddleware();

  store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authnApiService.middleware),
  });

  // epicMiddleware.run(rootEpic);

  return store;
}
