import { Div, ThemeProvider } from 'atomize';
import React, { ReactNode, useState } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { defaultTheme } from '@/shared/default-theme';
import { routePaths } from '@/shared/routing';
import { UserRole } from '@/shared/user-roles';
import { AppHeader, UserInfo } from '@/shared/ui';
import '@/app/styles/global.css';
import { Modal } from '@/shared/ui';
import { UnverifiedDrivers } from '@/widgets/unverified-drivers';
import { styled } from 'styletron-react';

interface AdminLayoutProps {
  children: ReactNode;
}

const theme = {
  ...defaultTheme,
};

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  const { t } = useTranslation();
  const [openDriversVerification, setOpenDriversVerification] = useState(false);
  const user: UserInfo = {
    fullName: 'Кайрат Асылбеков',
    role: UserRole.ADMIN,
  };

  const links = [
    {
      path: routePaths.dashboard,
      title: t('path_dashboard'),
    },
    {
      path: routePaths.orders,
      title: t('path_orders'),
    },
    {
      path: routePaths.drivers,
      title: t('path_drivers'),
    },
    {
      path: routePaths.users,
      title: t('path_users'),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Div bg="bg1" h="100vh" p="25px">
        <AppHeader user={user} links={links} openDriversVerification={() => setOpenDriversVerification(true)} />
        {children}
        <Modal isOpen={openDriversVerification} onClose={() => setOpenDriversVerification(false)}>
          <SliderWrapper>
            <UnverifiedDrivers />
          </SliderWrapper>
        </Modal>
      </Div>
    </ThemeProvider>
  );
};

const SliderWrapper = styled('section', {
  height: '600px',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  borderRadius: '20px',
});
