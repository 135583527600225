import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { styled } from 'styletron-react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  overlayTestId?: string;
  contentTestId?: string;
}

const modalStack: number[] = [];
const BASE_Z_INDEX = 1000;
let modalIdCounter = 0;

export function Modal(props: ModalProps) {
  const { isOpen, onClose, children, overlayTestId = 'modal-overlay', contentTestId = 'modal-content' } = props;
  const id = useRef(modalIdCounter++);

  useEffect(() => {
    if (!isOpen) return;

    modalStack.push(id.current);

    return () => {
      const index = modalStack.indexOf(id.current);
      if (index > -1) {
        modalStack.splice(index, 1);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isTopMost(id.current)) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown, { signal: abortSignal });

    return () => {
      abortController.abort();
    };
  }, [isOpen, onClose]);

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget && isTopMost(id.current)) {
      onClose();
    }
  };

  const currentZIndex = getZIndex(id.current);

  if (!isOpen) return null;

  return createPortal(
    <ModalWrapper $zIndex={currentZIndex}>
      <Overlay onClick={handleOverlayClick} data-testid={overlayTestId} $zIndex={currentZIndex} />
      <Content data-testid={contentTestId} $zIndex={currentZIndex + 1}>
        {children}
      </Content>
    </ModalWrapper>,
    document.body,
  );
}

const getZIndex = (id: number) => {
  const modalIndex = modalStack.indexOf(id);
  return modalIndex === -1 ? BASE_Z_INDEX : BASE_Z_INDEX + modalIndex * 2;
};

const isTopMost = (id: number) => {
  return modalStack.at(-1) === id;
};

const ModalWrapper = styled('div', (props: { $zIndex: number }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: props.$zIndex,
  pointerEvents: 'none',
}));

const Overlay = styled('div', (props: { $zIndex: number }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: props.$zIndex,
  pointerEvents: 'auto',
}));

const Content = styled('div', (props: { $zIndex: number }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'transparent',
  borderRadius: '20px',
  zIndex: props.$zIndex,
  minWidth: '300px',
  maxWidth: '100%',
  pointerEvents: 'auto',
}));
