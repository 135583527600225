import React from 'react';
import ReactSelect, {
  ActionMeta,
  ClearIndicatorProps,
  MultiValue,
  SingleValue,
  StylesConfig,
  components,
} from 'react-select';
import { styleVars } from '../../../default-theme';
import { Icon } from 'atomize';

export interface SelectOption<T> {
  label?: string;
  value: T;
  color?: string;
}

type MultiOrSingleValue<T, IsMulti extends boolean = false> = IsMulti extends true
  ? MultiValue<SelectOption<T>>
  : SingleValue<SelectOption<T>>;

interface SelectProps<T, IsMulti extends boolean = false> {
  options: SelectOption<T>[];
  placeholder: string;
  value?: MultiOrSingleValue<T, IsMulti>;
  isMulti?: IsMulti;
  onSelect: (value: MultiOrSingleValue<T, IsMulti>, actionMeta: ActionMeta<SelectOption<T>>) => void;
}

export function Select<T extends string | number | boolean, IsMulti extends boolean = false>(
  props: SelectProps<T, IsMulti>,
) {
  const { options, value, isMulti = false, onSelect, placeholder } = props;

  const handleChange = (selectedOption: MultiOrSingleValue<T, IsMulti>, actionMeta: ActionMeta<SelectOption<T>>) => {
    onSelect(selectedOption, actionMeta);
  };

  const colorStyles = getColorStyles<T>();

  return (
    <ReactSelect
      placeholder={placeholder}
      options={options}
      value={value}
      isMulti={isMulti}
      onChange={handleChange}
      styles={colorStyles}
      menuPortalTarget={document.body}
      isClearable
      components={{
        ClearIndicator: ClearIconIndicator,
      }}
      closeMenuOnSelect={!isMulti}
    />
  );
}

// React-select styles require type parametr T for value-based styles
function getColorStyles<T>(): StylesConfig<SelectOption<T>> {
  return {
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: '14px',
    }),
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
      };
    },
    control: (styles, { hasValue, menuIsOpen }) => ({
      ...styles,
      backgroundColor: styleVars.bg4,
      border: `1px solid ${menuIsOpen || hasValue ? styleVars.green : styleVars.initialInputBorderColor}`,
      borderRadius: '10px',
      padding: '2px 3px',
      cursor: 'pointer',
      boxShadow: menuIsOpen || hasValue ? styleVars.green : 'none',
      ':hover': {
        border: `1px solid ${menuIsOpen || hasValue ? styleVars.green : styleVars.initialInputBorderColor}`,
      },
      ':focus': {
        border: `1px solid ${styleVars.green}`,
      },
    }),
    menu: (styles) => ({
      ...styles,
      border: `1px solid ${styleVars.initialInputBorderColor}`,
      borderRadius: '10px',
      backgroundColor: styleVars.bg4,
      padding: '0px !important',
      zIndex: 9999,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      color: styleVars.secondaryText,
      backgroundColor: isFocused || isSelected ? '#4242424d' : styleVars.bg4,
      fontSize: '14px',
      ':hover': {
        backgroundColor: '#4242424d',
      },
      ':first-child': {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
      },
      ':last-child': {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'white',
      fontSize: '14px',
    }),
  };
}

function ClearIconIndicator<T>(props: ClearIndicatorProps<SelectOption<T>>) {
  return (
    <components.ClearIndicator {...props}>
      <Icon
        name="Cross"
        color="#B3B3B3"
        size="20px"
        pos="absolute"
        top="50%"
        right="2rem"
        transform="translateY(-50%)"
        cursor="pointer"
        data-testid="test-cross-icon"
      />
    </components.ClearIndicator>
  );
}
