import React, { FC, useEffect, useRef, useState } from 'react';
import { Div, Text, Button } from 'atomize';
import { UserIcon, ExitIcon } from '@/shared/assets/svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useStyletron } from 'styletron-react';
import { useSignOutMutation } from '@/features/login';
import { navigate } from 'gatsby';

import * as styles from './user-profile-menu.module.css';

interface UserProfileMenu {
  fullName: string;
  role: string;
}

export const UserProfileMenu: FC<UserProfileMenu> = ({ fullName, role }) => {
  const { t } = useTranslation();
  const [css] = useStyletron();
  const [signOut, signOutState] = useSignOutMutation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const onSignOut = () => {
    signOut({})
      .unwrap()
      .then(() => navigate('/login/'));
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={styles.dropdown} ref={dropdownRef}>
        <Div
          className={`${styles.dropdownToggle} ${isOpen && styles.active} ${css({ gap: '8px' })}`}
          rounded="10px"
          p={{ x: '14px', y: '8px' }}
          d="flex"
          bg="#ffffff0d"
          hoverBg="#2c2c2c"
          align="center"
          onClick={toggleDropdown}
          data-testid="toggle-div"
        >
          <UserIcon />
          <Text textColor="white" tag="p">
            {fullName}
          </Text>
          <Text textColor="secondary" tag="p">
            {t(role)}
          </Text>
        </Div>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <div className={`${styles.dropdownItem}`}>
              <Button
                className={` ${css({ gap: '10px', color: 'rgba(136, 136, 136, 1)' })}`}
                prefix={<ExitIcon dataTestId="exit-icon" />}
                bg="transparent"
                p="0"
                h="auto"
                d="flex"
                w="100%"
                align="center"
                justify="start"
                onClick={onSignOut}
                isLoading={signOutState.isLoading}
                disabled={signOutState.isLoading}
              >
                {t('logout')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
