import { Button, Div, Icon, Text } from 'atomize';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { styled, useStyletron } from 'styletron-react';

import { validatePhone } from '@/shared/utils';
import { useAppSelector } from '@/app/store';
import { MutationErrorResponse, useRequestVerificationCodeMutation } from '@/features/login';
import { PhoneInput, SiteLogo } from '@/shared/ui';

export interface LoginFormProps {
  onSuccess: () => void;
}

export function RequestLoginCodeForm(props: LoginFormProps) {
  const defaultPhoneNumber = useAppSelector((s) => (s.login.userName ? getMaskedPhone(s.login.userName) : ''));

  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [requestCode, requestCodeState] = useRequestVerificationCodeMutation();
  const { t } = useTranslation();
  const [css] = useStyletron();

  const clearPhoneNumber = () => setPhoneNumber('');

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const userName = phoneNumber.replaceAll(/[^\+\d]/g, '');
    requestCode({ userName }).then((res) => res.data && props.onSuccess());
  };

  const handlePhonePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const str = event.clipboardData.getData('text');
    const formatted = getPhoneBody(str);

    setPhoneNumber(formatted);
  };

  const requestError = requestCodeState.isError ? t((requestCodeState as MutationErrorResponse).error.code) : undefined;
  const formatError = validatePhone(phoneNumber);
  const error = formatError ? t('correctPhoneNumber') : requestError;

  return (
    <Form onSubmit={handleSubmit}>
      <Div w="100%" d="flex" flexDir="column" className={css({ gap: '25px' })}>
        <SiteLogo />
        <Div d="flex" flexDir="column" className={css({ gap: '4px' })}>
          <Text textColor="#ffffff80">{t('phoneNumber')}</Text>
          <PhoneInput
            value={phoneNumber}
            onChange={setPhoneNumber}
            onPaste={handlePhonePaste}
            status={error ? 'error' : 'default'}
            mask={mask}
            placeholder={t('enterYourPhone')}
            suffix={
              <Icon
                d={phoneNumber.length ? 'block' : 'none'}
                name="Cross"
                color="#B3B3B3"
                size="20px"
                pos="absolute"
                top="50%"
                right="1rem"
                transform="translateY(-50%)"
                cursor="pointer"
                onClick={clearPhoneNumber}
              />
            }
          />
          {error && (
            <Text tag="p" textColor="errorRed">
              {error}
            </Text>
          )}
        </Div>
        <Button
          bg="#28e267"
          color="black"
          rounded="12px"
          textColor="black"
          textWeight={600}
          type="submit"
          p="4px 0px"
          m={{ t: '8px' }}
          isLoading={requestCodeState.isLoading}
        >
          {t('next')}
        </Button>
      </Div>
    </Form>
  );
}

const Form = styled('form', {
  width: '454px',
  backgroundColor: '#101010',
  padding: '24px',
  display: 'flex',
  borderRadius: '20px',
});

const mask = '+996 (___) ___ ___';

/**
 * Turn '+996XXXXXXXXX' into '+996 (___) ___ ___'
 * @param phone string
 * @returns Phone number with mask applied
 */
function getMaskedPhone(phone: string) {
  let i = 0;
  let result = [] as string[];

  // Replace '_' with digits
  for (const mChar of mask) {
    const pChar = phone[i];

    if (mChar === pChar || mChar === '_') {
      result.push(pChar);
      i++;
    } else {
      result.push(mChar);
    }
  }

  return result.join('');
}

/**
 * Take pasteable substring from input phone string
 * @param input string
 * @returns 9 last digits in phone number
 */
function getPhoneBody(input: string) {
  // Take last 9 digits from the string
  const result = input
    .split('')
    .reverse()
    .filter((c) => /\d/.test(c))
    .slice(0, 9)
    .reverse()
    .join('');

  return result;
}
