import { Icon, Input } from 'atomize';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { SearchIcon } from '@/shared/assets/svg';
import { styled, useStyletron } from 'styletron-react';
import { styleVars } from '@/shared/default-theme';

interface SearchInputProps {
  placeholder: string;
  value: string;
  dataTestId?: string;
  onClear: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchInput: FC<SearchInputProps> = (props) => {
  const { value, placeholder, dataTestId, onChange, onClear, onKeyDown } = props;
  const [css] = useStyletron();
  const borderColor = value?.length > 0 ? 'green' : 'initialInputBorderColor';

  return (
    <Input
      className={css({ outline: styleVars.green })}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      borderColor={borderColor}
      focusBorderColor="green"
      p={{ x: '37px' }}
      textColor="white"
      bg="bg3"
      w="343px"
      prefix={<StyledSearchIcon data-testid="test-search-icon" />}
      suffix={
        value && (
          <Icon
            d="block"
            name="Cross"
            color="#B3B3B3"
            size="20px"
            pos="absolute"
            top="50%"
            right="0.5rem"
            transform="translateY(-50%)"
            cursor="pointer"
            onClick={onClear}
            data-testid="test-cross-icon"
          />
        )
      }
      data-testid={dataTestId}
    />
  );
};

const StyledSearchIcon = styled(SearchIcon, {
  position: 'absolute',
  top: '38%',
  left: '0.90rem',
});
