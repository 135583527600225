import { Driver } from '@/entities/driver/table';
import { DriverStatus } from '@/shared/driver-statuses';
import { TableColumn } from './table';
import React from 'react';

export const testDrivers: Driver[] = [
  {
    id: '6',
    fullName: 'Test Driver 6',
    phone: '+996 555 666 666',
    carBrand: 'BMW',
    carLicensePlate: 'TEST006',
    earnings24h: 1800,
    commission24h: 180,
    orders30days: 70,
    lastOrder: '2024-10-02 08:45',
    status: DriverStatus.ONLINE,
  },
  {
    id: '7',
    fullName: 'Test Driver 7',
    phone: '+996 555 777 777',
    carBrand: 'Hyundai',
    carLicensePlate: 'TEST007',
    earnings24h: 0,
    commission24h: 0,
    orders30days: 10,
    lastOrder: '2024-09-29 16:00',
    status: DriverStatus.OFFLINE,
  },
  {
    id: '8',
    fullName: 'Test Driver 8',
    phone: '+996 555 888 888',
    carBrand: 'Toyota',
    carLicensePlate: 'TEST008',
    earnings24h: 500,
    commission24h: 50,
    orders30days: 20,
    lastOrder: '2024-10-01 14:30',
    status: DriverStatus.LOW_BALANCE,
  },
  {
    id: '9',
    fullName: 'Test Driver 9',
    phone: '+996 555 999 999',
    carBrand: 'Mercedes',
    carLicensePlate: 'TEST009',
    earnings24h: 2500,
    commission24h: 250,
    orders30days: 90,
    lastOrder: '2024-10-02 12:00',
    status: DriverStatus.ONLINE,
  },
  {
    id: '10',
    fullName: 'Test Driver 10',
    phone: '+996 555 010 010',
    carBrand: 'Kia',
    carLicensePlate: 'TEST010',
    earnings24h: 0,
    commission24h: 0,
    orders30days: 2,
    lastOrder: '2024-09-25 09:30',
    status: DriverStatus.BLOCKED,
  },
  {
    id: '11',
    fullName: 'Test Driver 11',
    phone: '+996 555 111 112',
    carBrand: 'Chevrolet',
    carLicensePlate: 'TEST011',
    earnings24h: 1200,
    commission24h: 120,
    orders30days: 50,
    lastOrder: '2024-10-02 15:00',
    status: DriverStatus.ONLINE,
  },
  {
    id: '12',
    fullName: 'Test Driver 12',
    phone: '+996 555 222 223',
    carBrand: 'Nissan',
    carLicensePlate: 'TEST012',
    earnings24h: 300,
    commission24h: 30,
    orders30days: 15,
    lastOrder: '2024-09-28 11:45',
    status: DriverStatus.LOW_BALANCE,
  },
  {
    id: '13',
    fullName: 'Test Driver 13',
    phone: '+996 555 333 334',
    carBrand: 'Mitsubishi',
    carLicensePlate: 'TEST013',
    earnings24h: 100,
    commission24h: 10,
    orders30days: 5,
    lastOrder: '2024-09-27 14:00',
    status: DriverStatus.OFFLINE,
  },
  {
    id: '14',
    fullName: 'Test Driver 14',
    phone: '+996 555 444 445',
    carBrand: 'Audi',
    carLicensePlate: 'TEST014',
    earnings24h: 2700,
    commission24h: 270,
    orders30days: 100,
    lastOrder: '2024-10-02 13:45',
    status: DriverStatus.ONLINE,
  },
  {
    id: '15',
    fullName: 'Test Driver 15',
    phone: '+996 555 555 556',
    carBrand: 'Mazda',
    carLicensePlate: 'TEST015',
    earnings24h: 0,
    commission24h: 0,
    orders30days: 0,
    lastOrder: '2024-09-12 08:30',
    status: DriverStatus.BLOCKED,
  },
  {
    id: '16',
    fullName: 'Test Driver 16',
    phone: '+996 555 666 667',
    carBrand: 'Suzuki',
    carLicensePlate: 'TEST016',
    earnings24h: 600,
    commission24h: 60,
    orders30days: 25,
    lastOrder: '2024-10-01 10:30',
    status: DriverStatus.LOW_BALANCE,
  },
  {
    id: '17',
    fullName: 'Test Driver 17',
    phone: '+996 555 777 778',
    carBrand: 'Jeep',
    carLicensePlate: 'TEST017',
    earnings24h: 2200,
    commission24h: 220,
    orders30days: 85,
    lastOrder: '2024-10-02 16:00',
    status: DriverStatus.ONLINE,
  },
  {
    id: '18',
    fullName: 'Test Driver 18',
    phone: '+996 555 888 889',
    carBrand: 'Renault',
    carLicensePlate: 'TEST018',
    earnings24h: 0,
    commission24h: 0,
    orders30days: 3,
    lastOrder: '2024-09-21 13:15',
    status: DriverStatus.OFFLINE,
  },
  {
    id: '19',
    fullName: 'Test Driver 19',
    phone: '+996 555 999 990',
    carBrand: 'Subaru',
    carLicensePlate: 'TEST019',
    earnings24h: 1250,
    commission24h: 125,
    orders30days: 55,
    lastOrder: '2024-10-01 17:00',
    status: DriverStatus.ONLINE,
  },
  {
    id: '20',
    fullName: 'Test Driver 20',
    phone: '+996 555 010 011',
    carBrand: 'Peugeot',
    carLicensePlate: 'TEST020',
    earnings24h: 900,
    commission24h: 90,
    orders30days: 40,
    lastOrder: '2024-10-01 19:30',
    status: DriverStatus.AWAITING_REVIEW,
  },
  {
    id: '21',
    fullName: 'Test Driver 21',
    phone: '+996 555 111 113',
    carBrand: 'Opel',
    carLicensePlate: 'TEST021',
    earnings24h: 1600,
    commission24h: 160,
    orders30days: 60,
    lastOrder: '2024-10-02 14:15',
    status: DriverStatus.ONLINE,
  },
  {
    id: '22',
    fullName: 'Test Driver 22',
    phone: '+996 555 222 224',
    carBrand: 'Land Rover',
    carLicensePlate: 'TEST022',
    earnings24h: 0,
    commission24h: 0,
    orders30days: 1,
    lastOrder: '2024-09-17 17:00',
    status: DriverStatus.BLOCKED,
  },
  {
    id: '23',
    fullName: 'Test Driver 23',
    phone: '+996 555 333 335',
    carBrand: 'Skoda',
    carLicensePlate: 'TEST023',
    earnings24h: 1400,
    commission24h: 140,
    orders30days: 65,
    lastOrder: '2024-10-02 09:30',
    status: DriverStatus.ONLINE,
  },
  {
    id: '24',
    fullName: 'Test Driver 24',
    phone: '+996 555 444 446',
    carBrand: 'Dodge',
    carLicensePlate: 'TEST024',
    earnings24h: 0,
    commission24h: 0,
    orders30days: 0,
    lastOrder: '2024-09-19 11:45',
    status: DriverStatus.OFFLINE,
  },
  {
    id: '25',
    fullName: 'Test Driver 25',
    phone: '+996 555 555 557',
    carBrand: 'Chrysler',
    carLicensePlate: 'TEST025',
    earnings24h: 1950,
    commission24h: 195,
    orders30days: 75,
    lastOrder: '2024-10-02 15:30',
    status: DriverStatus.AWAITING_REVIEW,
  },
];

export const emptyDrivers: Driver[] = [];

export const singleDriver: Driver[] = [
  {
    id: '6',
    fullName: 'Single Test Driver',
    phone: '+996 555 999 999',
    carBrand: 'Porsche',
    carLicensePlate: 'TEST999',
    earnings24h: 3000,
    commission24h: 300,
    orders30days: 100,
    lastOrder: '2024-10-01 23:59',
    status: DriverStatus.ONLINE,
  },
];

export const longNameDriver: Driver[] = [
  {
    id: '7',
    fullName: 'Very Long Name That Might Cause Layout Issues In The Table',
    phone: '+996 555 888 888',
    carBrand: 'Very Long Car Brand Name',
    carLicensePlate: 'TESTLONG',
    earnings24h: 1000,
    commission24h: 100,
    orders30days: 40,
    lastOrder: '2024-10-01 12:34',
    status: DriverStatus.ONLINE,
  },
];

export const testColumns: TableColumn<Driver>[] = [
  { title: 'Name', dataIndex: 'fullName' },
  { title: 'Phone', dataIndex: 'phone' },
  { title: 'Car', dataIndex: 'carBrand' },
  { title: 'License', dataIndex: 'carLicensePlate' },
  { title: 'Earnings', dataIndex: 'earnings24h', sortable: true },
  { title: 'Commission', dataIndex: 'commission24h' },
  { title: 'Orders', dataIndex: 'orders30days' },
  { title: 'Last Order', dataIndex: 'lastOrder' },
  {
    title: 'Status',
    dataIndex: 'status',
    renderCellContent: ({ status }) => <p data-testid="test-render-prop">{status}</p>,
  },
];
