export enum UserRole {
  ADMIN = 'Admin',
  OPERATOR = 'Operator',
  USER = 'User',
}

export const userRoles = [UserRole.ADMIN, UserRole.OPERATOR, UserRole.USER];

export function extractRoleFromARN(arn: string): UserRole {
  // ARN of an IAM role in the following form
  // `arn:aws:iam::368080513008:role/Operator`.
  const roleRE = new RegExp(`^arn:[^:]+:iam::\\d+:role/.*?(${userRoles.join('|')})$`);
  const match = arn.match(roleRE);

  if (!match) {
    throw new Error('Could not determine the role of the user.');
  }

  return match[1] as UserRole;
}
