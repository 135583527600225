import React from 'react';

export const ExitIcon = (props: { dataTestId: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-testid={props.dataTestId}
  >
    <path
      d="M11.3333 12.1667L10.1875 10.9583L12.3125 8.83333H5.5V7.16667H12.3125L10.1875 5.04167L11.3333 3.83333L15.5 8L11.3333 12.1667ZM0.5 15.5V0.5H8V2.16667H2.16667V13.8333H8V15.5H0.5Z"
      fill="#888888"
    />
  </svg>
);
