import React from 'react';
import { UnverifiedDriver } from '@/entities/unverified-driver';
import { styled } from 'styletron-react';
import { styleVars } from '@/shared/default-theme';
import { Button, Icon } from 'atomize';
import { CancelIcon } from '@/shared/assets/svg';
import { useTranslation } from 'react-i18next';
import { DriverDocsView } from '@/entities/driver/docs';

interface UnverifiedDriverViewProps {
  driverInfo: UnverifiedDriver;
  onApprove: (driverId: string) => void;
  onReject: (driverId: string) => void;
  onBlock: (driverId: string) => void;
}

export function UnverifiedDriverView(props: UnverifiedDriverViewProps) {
  const { driverInfo, onApprove, onReject, onBlock } = props;
  const { t } = useTranslation();

  return (
    <CardWrapper>
      <CardHeader>
        <FlexRow>
          <Avatar
            src={driverInfo.avatar}
            alt={t('unverifiedDriverCard_driverAvatar', { driverName: driverInfo.fullName })}
          />
          <FlexColumn>
            <Title>{driverInfo.fullName}</Title>
            <SecondaryText $fontSize="20px">{driverInfo.phone}</SecondaryText>
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <SecondaryText>{t('unverifiedDriverCard_verificationSentDate')}</SecondaryText>
            <SecondaryText $style={{ textAlign: 'right' }} $fontWeight={500}>
              {driverInfo.verificationSentDate}
            </SecondaryText>
          </FlexColumn>
          <ActionButton
            $bgColor={actionButtonColor.get('block')}
            $style={{ color: styleVars.red }}
            prefix={<CancelIcon />}
            onClick={() => onBlock(driverInfo.id)}
            data-testid="test-block-button"
          >
            {t('block')}
          </ActionButton>
        </FlexRow>
      </CardHeader>
      <DriverDocsView driverInfo={driverInfo} showDocs />
      <FlexRow>
        <ActionButton
          $bgColor={actionButtonColor.get('approve')}
          prefix={<Icon name="Checked" size="20px" pos="relative" top="3%" />}
          onClick={() => onApprove(driverInfo.id)}
          data-testid="test-approve-button"
        >
          {t('accept')}
        </ActionButton>
        <ActionButton
          $bgColor={actionButtonColor.get('cancel')}
          prefix={<Icon name="Cross" size="20px" pos="relative" top="5%" />}
          onClick={() => onReject(driverInfo.id)}
          data-testid="test-cancel-button"
        >
          {t('reject')}
        </ActionButton>
      </FlexRow>
    </CardWrapper>
  );
}

const actionButtonColor = new Map([
  ['block', '#342323'],
  ['approve', styleVars.green],
  ['cancel', styleVars.red],
]);

const CardWrapper = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  backgroundColor: styleVars.bg4,
  padding: '24px',
  borderRadius: '10px',
});

const CardHeader = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
});

const FlexRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const FlexColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
});

const Avatar = styled('img', {
  borderRadius: '999px',
  width: '60px',
  height: '60px',
});

const Title = styled('h2', {
  fontSize: '20px',
  lineHeight: '30px',
  color: 'white',
  fontWeight: 400,
});

const SecondaryText = styled('p', (props: { $fontSize?: string; $fontWeight?: number }) => ({
  fontSize: props.$fontSize || '14px',
  fontWeight: props.$fontWeight || 400,
  color: styleVars.secondaryText,
  wordBreak: 'break-word',
  width: '203px',
}));

const ActionButton = styled(Button, (props: { $bgColor?: string }) => ({
  backgroundColor: props.$bgColor || 'white',
  borderRadius: '10px',
  padding: '6px 14px',
  fontSize: '16px',
  fontWeight: 700,
  color: 'black',
  width: '100%',

  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));
