import React, { SVGProps } from 'react';

export const CancelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_2558_9758)">
      <path
        d="M9.99996 1.66699C5.39996 1.66699 1.66663 5.40033 1.66663 10.0003C1.66663 14.6003 5.39996 18.3337 9.99996 18.3337C14.6 18.3337 18.3333 14.6003 18.3333 10.0003C18.3333 5.40033 14.6 1.66699 9.99996 1.66699ZM3.33329 10.0003C3.33329 6.31699 6.31663 3.33366 9.99996 3.33366C11.5416 3.33366 12.9583 3.85866 14.0833 4.74199L4.74163 14.0837C3.85829 12.9587 3.33329 11.542 3.33329 10.0003ZM9.99996 16.667C8.45829 16.667 7.04163 16.142 5.91663 15.2587L15.2583 5.91699C16.1416 7.04199 16.6666 8.45866 16.6666 10.0003C16.6666 13.6837 13.6833 16.667 9.99996 16.667Z"
        fill="#FC4F4F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2558_9758">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
