import { styleVars } from '@/shared/default-theme';
import { Badge, Image } from '@/shared/ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Icon } from 'atomize';
import React, { useState } from 'react';
import { styled } from 'styletron-react';
import { UnverifiedDriver } from '@/entities/unverified-driver';

interface DriverDocsProps {
  driverInfo: UnverifiedDriver;
  isColumn?: boolean;
  splitted?: boolean;
  showDocs?: boolean;
}

export function DriverDocsView(props: DriverDocsProps) {
  const { driverInfo, isColumn, splitted, showDocs } = props;
  const { t } = useTranslation();

  const [showLicense, setShowLicense] = useState(false);
  const [showTechPassport, setShowTechPassport] = useState(false);

  const renderDocumentField = (label: string, content: React.ReactNode) => (
    <DocumentField>
      <p>{label}</p> {content}
    </DocumentField>
  );

  const renderDocumentImages = (
    imageSrc: string,
    documentType: 'licenseFront' | 'licenseBack' | 'techPassportFront' | 'techPassportBack',
  ) => <Image src={imageSrc} alt={altForDocuments.get(documentType) || ''} clickToEnlarge />;

  const toggleLicense = () => setShowLicense((prev) => !prev);
  const toggleTechPassport = () => setShowTechPassport((prev) => !prev);

  const altForDocuments = new Map([
    ['licenseFront', t('driverDocs_licenseFront')],
    ['licenseBack', t('driverDocs_licenseBack')],
    ['techPassportFront', t('driverDocs_techPassportFront')],
    ['techPassportBack', t('driverDocs_techPassportBack')],
  ]);

  return (
    <Wrapper $isColumn={isColumn} $splitted={splitted}>
      <DocumentInfo $splitted={splitted}>
        <DocumentTitle>{t('driverDocs_license')}</DocumentTitle>
        {renderDocumentField(t('inn'), driverInfo.inn)}
        {renderDocumentField(t('issuingAuthority'), driverInfo.issuingAuthority)}
        {renderDocumentField(t('issueDate'), driverInfo.issueDate)}
        {showDocs ? (
          <DocsImages $isVisible>
            {renderDocumentImages(driverInfo.licenseFrontPhoto, 'licenseFront')}
            {renderDocumentImages(driverInfo.licenseBackPhoto, 'licenseBack')}
          </DocsImages>
        ) : (
          <DocsImegesWrapper>
            <DocumentToggleButtom
              onClick={toggleLicense}
              suffix={
                <Icon
                  name={showLicense ? 'UpArrow' : 'DownArrow'}
                  size="20px"
                  pos="relative"
                  top="5%"
                  left="3%"
                  color={styleVars.secondaryText}
                />
              }
            >
              {t('driverDocs_licensePhotoOpen')}
            </DocumentToggleButtom>
            {showLicense && (
              <DocsImages $isVisible>
                {renderDocumentImages(driverInfo.licenseFrontPhoto, 'licenseFront')}
                {renderDocumentImages(driverInfo.licenseBackPhoto, 'licenseBack')}
              </DocsImages>
            )}
          </DocsImegesWrapper>
        )}
      </DocumentInfo>

      <DocumentInfo $splitted={splitted}>
        <DocumentTitle>{t('driverDocs_techPassport')}</DocumentTitle>
        {renderDocumentField(t('carModel'), driverInfo.carModel)}
        {renderDocumentField(t('carColor'), <Badge bgColor={driverInfo.carColor} value={driverInfo.carColor} />)}
        {renderDocumentField(t('licensePlate'), driverInfo.licensePlate)}
        {renderDocumentField(t('manufactureYear'), driverInfo.manufactureYear)}
        {showDocs ? (
          <DocsImages $isVisible>
            {renderDocumentImages(driverInfo.licenseFrontPhoto, 'licenseFront')}
            {renderDocumentImages(driverInfo.licenseBackPhoto, 'licenseBack')}
          </DocsImages>
        ) : (
          <DocsImegesWrapper>
            <DocumentToggleButtom
              onClick={toggleTechPassport}
              suffix={
                <Icon
                  name={showTechPassport ? 'UpArrow' : 'DownArrow'}
                  size="20px"
                  pos="relative"
                  top="5%"
                  left="3%"
                  color={styleVars.secondaryText}
                />
              }
            >
              {t('driverDocs_techPassportPhotoOpen')}
            </DocumentToggleButtom>
            {showTechPassport && (
              <DocsImages $isVisible>
                {renderDocumentImages(driverInfo.licenseFrontPhoto, 'licenseFront')}
                {renderDocumentImages(driverInfo.licenseBackPhoto, 'licenseBack')}
              </DocsImages>
            )}
          </DocsImegesWrapper>
        )}
      </DocumentInfo>
    </Wrapper>
  );
}

const Wrapper = styled('div', (props: { $isColumn?: boolean; $splitted?: boolean }) => ({
  display: 'flex',
  flexDirection: props.$isColumn ? 'column' : 'row',
  gap: '24px',
  width: '100%',
}));

const DocumentInfo = styled('div', (props: { $splitted?: boolean }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  backgroundColor: styleVars.bg4,
  padding: props.$splitted ? '24px' : '0px',
  borderRadius: props.$splitted ? '20px' : '0',
  width: '46%',
}));

const DocumentTitle = styled('h3', {
  color: '#8F8F8F',
  fontWeight: 'normal',
  fontSize: '20px',
  lineHeight: '24px',
  margin: 0,
});

const DocumentField = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  gap: '50px',
});

const DocsImegesWrapper = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  alignContent: 'space-between',
  gap: '10px',
}));

const DocsImages = styled('div', (props: { $isVisible?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '15px',
  transition: 'height 0.5s ease, opacity 0.5s ease',
  opacity: props.$isVisible ? 1 : 0,
  height: props.$isVisible ? 'auto' : '0',
  width: '100%',
}));

const DocumentToggleButtom = styled(Button, {
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  color: 'white',
  fontSize: '16px !important',
  padding: '0px !important',
});
