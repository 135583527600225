import React, { ReactNode } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { styled, useStyletron } from 'styletron-react';
import { Pagination } from '../pagination';
import { SortDirection } from '@/shared/sort-direction';

type DataIndex<T> = Exclude<keyof T, Symbol>;

export interface TableColumn<T extends Record<string, any>> {
  title: string;
  dataIndex: DataIndex<T>;
  sortable?: boolean;
  renderCellContent?: (item: T) => ReactNode;
}

export interface TableProps<T extends { id: string }> {
  columns: TableColumn<T>[];
  dataSource: T[];
  currentPage: number;
  totalCount: number;
  pageSize: number;
  sorting: { sortBy: DataIndex<T>; sortDirection: SortDirection };
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onSort: (sortBy: DataIndex<T>, direction: SortDirection) => void;
}

export function Table<T extends { id: string }>(props: TableProps<T>) {
  const {
    columns,
    dataSource,
    currentPage,
    pageSize,
    totalCount,
    sorting,
    isLoading = false,
    onPageChange,
    onSort,
  } = props;
  const { t } = useTranslation();
  const [css] = useStyletron();

  const handleFieldSort = (field: DataIndex<T>) => {
    if (sorting.sortDirection === SortDirection.DESC) {
      onSort(field, SortDirection.ASC);
    } else {
      onSort(field, SortDirection.DESC);
    }
  };

  const renderColumnCell = (item: T, col: TableColumn<T>) => {
    const tableCellKey = col.dataIndex;
    const tableCellChildren = col.renderCellContent
      ? col.renderCellContent(item)
      : renderDefaultCellContent(String(item[col.dataIndex]));

    return (
      <TableCell key={tableCellKey}>
        {isLoading ? <SkeletonLoader $width="16px" data-testid="test-skeleton-loader" /> : tableCellChildren}
      </TableCell>
    );
  };

  const renderTableBody = () => {
    if (isLoading) {
      return Array.from({ length: pageSize }).map((_, index) => (
        <TableRow key={index} $odd={index % 2 !== 0}>
          <TableCell>
            <Span $secondary>
              <SkeletonLoader $circle $width="8px" data-testid="test-skeleton-loader" />
            </Span>
          </TableCell>
          {columns.map((column) => (
            <TableCell key={column.dataIndex}>
              <SkeletonLoader data-testid="test-skeleton-loader" />
            </TableCell>
          ))}
        </TableRow>
      ));
    }

    if (dataSource.length === 0) {
      return (
        <TableRow $odd>
          <TableCell colSpan={columns.length + 1}>
            <p className={css({ textAlign: 'center' })}>{t('emptyTable')}</p>
          </TableCell>
        </TableRow>
      );
    }

    return dataSource.map((item, index) => (
      <TableRow key={item.id} $odd={index % 2 !== 0}>
        <TableCell>
          <Span $secondary>{index + 1}</Span>
        </TableCell>
        {columns.map((column) => renderColumnCell(item, column))}
      </TableRow>
    ));
  };

  return (
    <OuterContainer>
      <TableContainer>
        <TableElement>
          <TableHead>
            <TableRow $odd={true}>
              <TableHeadCell>
                <Title>#</Title>
              </TableHeadCell>
              {columns.map((column) => {
                const activeSortableCellColor =
                  column.sortable &&
                  column.dataIndex === sorting.sortBy &&
                  sortDirectionColors.get(sorting.sortDirection);
                return (
                  <TableHeadCell
                    key={column.dataIndex}
                    onClick={column.sortable ? () => handleFieldSort(column.dataIndex) : undefined}
                    tabIndex={column.sortable ? 0 : undefined}
                    $isSortable={column.sortable}
                    $sortDirectionColor={activeSortableCellColor}
                    data-testid={column.sortable && `test-sortCell-${sorting.sortDirection}`}
                  >
                    <Title $sortDirectionColor={activeSortableCellColor}>{column.title}</Title>
                  </TableHeadCell>
                );
              })}
            </TableRow>
          </TableHead>
          <tbody>{renderTableBody()}</tbody>
        </TableElement>
      </TableContainer>
      <PaginationWrapper>
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
          onPageChange={onPageChange}
          showBtnTitle
        />
      </PaginationWrapper>
    </OuterContainer>
  );
}

const renderDefaultCellContent = (value: string) => <Span>{String(value)}</Span>;
const sortDirectionColors = new Map([
  [SortDirection.ASC, '#FC4F4F'],
  [SortDirection.DESC, '#28E267'],
]);

const OuterContainer = styled('div', {
  width: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
});

const TableContainer = styled('div', {
  width: '100%',
  overflowX: 'auto',
  borderRadius: '10px',
  marginBottom: '10px',

  '::-webkit-scrollbar': {
    height: '8px',
    backgroundColor: 'rgb(22, 22, 22)',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'rgb(22, 22, 22)',
    boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.3)',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#333',
    backgroundImage: '#333',
    border: '3px solid #333',
    borderRadius: '10px',
    backgroundClip: 'padding-box',
    transition: 'all 0.2s ease',
  },

  '::-webkit-scrollbar-button:single-button:start': {
    display: 'none',
  },

  '::-webkit-scrollbar-button:single-button:end': {
    display: 'none',
  },
  '::-webkit-scrollbar-corner': {
    backgroundColor: 'red',
  },

  scrollbarWidth: 'auto',
  scrollbarColor: 'transparent ',
});

const TableElement = styled('table', {
  minWidth: '100%',
  width: 'max-content',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
});

const TableHead = styled('thead', {
  backgroundColor: '#222',
  color: 'white',
});

const TableHeadCell = styled('th', (props: { $isSortable?: boolean; $sortDirectionColor?: string | false }) => ({
  position: 'relative',
  padding: '14px',
  color: props.$sortDirectionColor || 'white',
  textAlign: 'left',
  cursor: props.$isSortable ? 'pointer' : 'default',
  borderBottom: props.$sortDirectionColor ? `3px solid ${props.$sortDirectionColor}` : 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

const TableRow = styled('tr', (props: { $odd?: boolean }) => ({
  backgroundColor: props.$odd ? '#292929' : '#202020',
  padding: '17px',
}));

const TableCell = styled('td', {
  textAlign: 'left',
  padding: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  minWidth: '20px',
  color: 'white',
});

const PaginationWrapper = styled('section', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '26px 0px',
});

const Span = styled('span', (props: { $secondary?: boolean }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: props.$secondary ? '#8b8b8b' : 'white',
}));

const Title = styled('span', (props: { $sortDirectionColor?: string | false }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: props.$sortDirectionColor || '#919191',
}));

const SkeletonLoader = styled('div', (props: { $circle?: boolean; $width?: string }) => ({
  width: props.$width || '100%',
  height: '16px',
  borderRadius: props.$circle ? '50%' : '4px',
  backgroundColor: 'black',
  backgroundImage: `linear-gradient(
    90deg,
    rgba(217, 217, 217, 0.2) 0%,
    rgba(217, 217, 217, 0.2) 25%,
    rgba(115, 115, 115, 0.2) 50%,
    rgba(217, 217, 217, 0.2) 75%,
    rgba(217, 217, 217, 0.2) 100%
  )`,
  backgroundSize: '200% 100%',
  animationDuration: '3.5s',
  animationIterationCount: 'infinite',
  animationName: {
    from: {
      backgroundPosition: '200% 0',
    },
    to: {
      backgroundPosition: '-200% 0',
    },
  },
}));
