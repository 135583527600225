import React from 'react';
import { styled } from 'styletron-react';
import { Icon } from 'atomize';
import { styleVars } from '../../../default-theme';
import { usePagination } from './use-pagination';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface PaginationProps {
  currentPage: number;
  onPageChange: (page: number) => void;
  totalCount: number;
  pageSize: number;
  showBtnTitle?: boolean;
}

export function Pagination(props: PaginationProps) {
  const { currentPage, pageSize, totalCount, showBtnTitle, onPageChange } = props;
  const { t } = useTranslation();

  const pageNumbers = usePagination({
    currentPage,
    totalCount,
    pageSize,
    siblingCount: 1,
  });

  const lastPage = pageNumbers.at(-1);
  return (
    <>
      <PrevPageButton
        disabled={currentPage <= 1}
        onClick={() => onPageChange(currentPage - 1)}
        data-testid="test-pagination-prev-btn"
      >
        <Icon name="LeftArrow" size="20px" color={currentPage <= 1 ? styleVars.secondary : 'white'} />
        {showBtnTitle && t('pagination_prevPageButton')}
      </PrevPageButton>
      <PageNumbersWrapper data-testid="test-pagination-pages">
        {pageNumbers.map((pageNumber) =>
          typeof pageNumber === 'number' ? (
            <PageNumber
              key={pageNumber}
              $isActive={currentPage === pageNumber}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </PageNumber>
          ) : (
            <EllipsisSpan key={pageNumber}>&#8230;</EllipsisSpan>
          ),
        )}
      </PageNumbersWrapper>
      <NextPageButton
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(currentPage + 1)}
        data-testid="test-pagination-next-btn"
      >
        {showBtnTitle && t('pagination_nextPageButton')}
        <Icon name="RightArrow" size="20px" color={currentPage === lastPage ? styleVars.secondary : 'white'} />
      </NextPageButton>
    </>
  );
}

const PageNumbersWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
});

const NextPageButton = styled('button', {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  transition: 'color 0.3s ease-in-out',
  ':disabled': {
    color: styleVars.secondary,
  },
});

const PrevPageButton = styled('button', {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  transition: 'color 0.3s ease-in-out',
  ':disabled': {
    color: styleVars.secondary,
  },
});

const PageNumber = styled('button', (props: { $isActive: boolean }) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: props.$isActive ? 'white' : styleVars.secondary,
  transition: 'color 0.3s ease-in-out',
  fontSize: '16px',
  fontWeight: 400,
}));

const EllipsisSpan = styled('span', {
  display: 'inline-block',
  fontSize: '1.2em',
  lineHeight: '1',
  verticalAlign: 'middle',
  margin: '0 0.2em',
  color: styleVars.secondary,
});
