import { useMask } from '@react-input/mask';
import React from 'react';
import { StyledInput, StyledInputProps } from '../styled-input';

export interface PhoneInputProps extends Omit<StyledInputProps, 'onChange'> {
  value?: string;
  onChange: (value: string) => void;
  /**
   * Mask for phone number input
   * @default '+996 (___) ___ ___'
   */
  mask?: string;
}

export function PhoneInput(props: PhoneInputProps) {
  const { value, onChange, mask, ...inputProps } = props;

  const maskRef = useMask({
    mask: props.mask ?? '+996 (___) ___ ___',
    showMask: true,
    replacement: { _: /\d/ },
  });

  const handleChange: StyledInputProps['onChange'] = (e) => props.onChange(e.target.value);

  return <StyledInput ref={maskRef} value={props.value} onChange={handleChange} {...inputProps} />;
}
