import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SessionUser } from '@/app/clients';
import { authnApiService } from '@/features/login';

const initialState = {
  currentUser: null as Nullable<SessionUser>,
  currentUserSession: null as Nullable<string>,
  userName: null as Nullable<string>,
  verificationCode: null as Nullable<string>,
  error: null as Nullable<string>,
  initializing: true,
  loading: false,
};

type LoginState = typeof initialState;
type PickLogin<Keys extends keyof LoginState> = PickRequired<LoginState, Keys>;

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clientInitRequested() {
      // Required to trigger an epic
    },
    clientInitSucceeded(state, action: PayloadAction<PickLogin<'currentUser'>>) {
      state.currentUser = action.payload.currentUser;
      state.initializing = false;
      state.loading = false;
      state.error = null;
    },
    clientInitFailed(state, action: PayloadAction<PickLogin<'error'>>) {
      state.error = action.payload.error;
      state.initializing = false;
    },

    verificationCodeRequested(state, action: PayloadAction<PickLogin<'userName'>>) {
      state.userName = action.payload.userName;
      state.loading = true;
      state.error = null;
    },
    verificationCodeRequestRejected(state, action: PayloadAction<PickLogin<'error'>>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    verificationCodeProvided(state, action: PayloadAction<PickLogin<'verificationCode'>>) {
      state.verificationCode = action.payload.verificationCode;
      state.loading = true;
      state.error = null;
    },
    verificationCodeAccepted(state, action: PayloadAction<PickLogin<'currentUserSession'>>) {
      state.currentUserSession = action.payload.currentUserSession;
      state.loading = false;
      state.error = null;
    },
    loginFailed(state, action: PayloadAction<PickLogin<'error'>>) {
      state.error = action.payload.error;
      state.loading = false;
    },

    logoutRequested(state) {
      state.loading = true;
      state.error = null;
    },
    logoutSucceeded(state) {
      state.currentUser = null;
      state.currentUserSession = null;
      state.loading = false;
      state.error = null;
    },
    logoutFailed(state, action: PayloadAction<PickLogin<'error'>>) {
      state.error = action.payload.error;
      state.loading = false;
      state.currentUser = null;
      state.currentUserSession = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authnApiService.endpoints.initClient.matchFulfilled, (state, { payload }) => {
        state.currentUser = payload?.currentUser ?? null;
      })
      .addMatcher(authnApiService.endpoints.requestVerificationCode.matchFulfilled, (state, { payload }) => {
        state.currentUserSession = payload?.currentSession ?? null;
        state.userName = payload?.userName ?? null;
      })
      .addMatcher(authnApiService.endpoints.sendVerificationCode.matchFulfilled, (state, { payload }) => {
        state.currentUser = payload?.currentUser ?? null;
      })
      .addMatcher(authnApiService.endpoints.signOut.matchFulfilled, (state) => {
        state.currentUser = null;
        state.currentUserSession = null;
      });
  },
});

export const loginActions = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
