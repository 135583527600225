import React, { Children, useState } from 'react';
import { styled } from 'styletron-react';
import Slider, { Settings as SlickSettings, CustomArrowProps } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NextIcon, PrevIcon } from '@/shared/assets/svg';

interface SliderProps {
  children: React.ReactNode;
  className?: string;
}

interface ArrowProps extends CustomArrowProps {
  isActive: boolean;
}

export function CustomSlider(props: SliderProps) {
  const { children, className } = props;
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings: SlickSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    draggable: false,
    nextArrow: <NextArrow isActive={currentSlide === Children.count(children) - 1} />,
    prevArrow: <PrevArrow isActive={currentSlide === 0} />,
    customPaging: (i: number) => <Dot $isActive={i === currentSlide} />,
    beforeChange: (_prev: number, next: number) => setCurrentSlide(next),
  };

  return (
    <Slider className={className} {...settings}>
      {children}
    </Slider>
  );
}

const NextArrow = ({ onClick, isActive }: ArrowProps) => {
  return (
    <ResetButton
      $style={{
        position: 'absolute',
        bottom: '-50px',
        right: '30%',
      }}
      onClick={onClick}
    >
      <NextIcon pathColor={isActive ? '#737373' : 'white'} />
    </ResetButton>
  );
};

const PrevArrow = ({ onClick, isActive }: ArrowProps) => {
  return (
    <ResetButton
      $style={{
        position: 'absolute',
        bottom: '-50px',
        left: '30%',
      }}
      onClick={onClick}
    >
      <PrevIcon pathColor={isActive ? '#737373' : 'white'} />
    </ResetButton>
  );
};

const Dot = styled('div', (props: { $isActive: boolean }) => ({
  backgroundColor: props.$isActive ? 'white' : '#737373',
  width: '16px',
  height: '16px',
  borderRadius: '999px',

  ':active': {
    backgroundColor: 'white',
  },
}));

const ResetButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
});
