import React, { SVGProps } from 'react';
export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    data-testid="logo"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx="4.01253" cy="14.9222" rx="2.95735" ry="3.07763" fill="white" />
    <ellipse cx="19.9981" cy="14.9222" rx="2.95735" ry="3.07763" fill="white" />
    <ellipse cx="4.01253" cy="4.14233" rx="2.95735" ry="3.07763" fill="white" />
    <ellipse cx="12.0053" cy="8.76721" rx="2.95735" ry="3.07763" fill="white" />
    <rect
      width="14.9107"
      height="6.09958"
      rx="3.04979"
      transform="matrix(0.866212 0.499677 -0.470116 0.882605 2.86743 0)"
      fill="white"
      fillOpacity="0.1"
    />
    <rect
      x="6.96973"
      y="1.0647"
      width="15.368"
      height="5.9147"
      rx="2.95735"
      transform="rotate(90 6.96973 1.0647)"
      fill="white"
      fillOpacity="0.1"
    />
    <rect
      x="22.9556"
      y="1.0647"
      width="15.368"
      height="5.9147"
      rx="2.95735"
      transform="rotate(90 22.9556 1.0647)"
      fill="white"
      fillOpacity="0.1"
    />
    <rect
      width="14.9107"
      height="6.09958"
      rx="3.04979"
      transform="matrix(0.866212 -0.499677 0.470116 0.882605 8.21667 7.45068)"
      fill="white"
      fillOpacity="0.1"
    />
    <ellipse cx="19.9981" cy="4.14233" rx="2.95735" ry="3.07763" fill="white" />
  </svg>
);
