export enum DriverStatus {
  ONLINE = 'ONLINE',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  LOW_BALANCE = 'LOW_BALANCE',
  NO_BALANCE = 'NO_BALANCE',
  BLOCKED = 'BLOCKED',
  OFFLINE = 'OFFLINE',

  NEW_DRIVER = 'NEW_DRIVER',
  NEED_DOCS = 'NEED_DOCS',
  VERIFIED = 'VERIFIED',
  ORDER_ON_HOLD = 'ORDER_ON_HOLD',
  ORDER_ACCEPTED = 'ORDER_ACCEPTED',
  DELETED = 'DELETED',
}
