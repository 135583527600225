export const styleVars = {
  bg1: '#161616',
  bg2: '#101010',
  bg3: '#FFFFFF0D',
  bg4: '#222222',
  green: '#28e267',
  yellow: '#ffc700',
  aqua: '#1ABC9C',
  blue: '#3498DB',
  red: '#fc4f4f',
  errorRed: '#ff3633',
  defaultInputBorder: '#393939',
  initialInputBorderColor: '#424242',
  secondary: '#FFFFFF80',
  inactive: '#7a7a7a',
  secondaryText: '#888888',
};

export const defaultTheme = {
  rounded: {
    xs: '8px',
    s: '10px',
    md: '12px',
  },
  colors: {
    bg1: styleVars.bg1,
    bg2: styleVars.bg2,
    bg3: styleVars.bg3,
    bg4: styleVars.bg4,
    green: styleVars.green,
    yellow: styleVars.yellow,
    red: styleVars.red,
    errorRed: styleVars.errorRed,
    defaultInputBorder: styleVars.defaultInputBorder,
    initialInputBorderColor: styleVars.initialInputBorderColor,
    secondary: styleVars.secondary,
  },
};
