import React, { useState } from 'react';
import { Modal } from '../modal';
import { styled } from 'styletron-react';

type SizeWithUnits = `${number}${'px' | '%' | 'em'}`;

interface ImageProps {
  src: string;
  alt: string;
  width?: SizeWithUnits;
  height?: SizeWithUnits;
  clickToEnlarge?: boolean;
}

export function Image(props: ImageProps) {
  const { src, alt, clickToEnlarge, width = '210px', height = '110px' } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ThumbnailImage
        src={src}
        alt={alt}
        $width={width}
        $height={height}
        onClick={clickToEnlarge ? () => setIsOpen(true) : undefined}
        data-testid="test-thumbnail-image"
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ZoomImage src={src} alt={alt} data-testid="test-zoom-image" />
      </Modal>
    </>
  );
}

const ThumbnailImage = styled('img', (props: { $width: string; $height: string }) => ({
  width: props.$width,
  height: props.$height,
  cursor: 'pointer',
  borderRadius: '10px',
  objectFit: 'cover',
  flex: '1 1 50%',
  maxWidth: '100%',
}));

const ZoomImage = styled('img', {
  width: '100%',
  height: 'auto',
  maxWidth: '90vw',
  maxHeight: '90vh',
  borderRadius: '10px',
});
