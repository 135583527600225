import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import React, { FC } from 'react';
import * as styles from './link.module.css';

interface CustomLinkProps<T = {}> extends Omit<GatsbyLinkProps<T>, 'ref'> {}

export const Link: FC<CustomLinkProps> = (props) => {
  const { activeClassName = styles.activeLink, className = styles.link } = props;
  return <GatsbyLink className={className} activeClassName={activeClassName} {...props} />;
};
