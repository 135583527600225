// prettier-ignore
const supportedCodes = [
  220, 221, 222, 223, 223, 224, 225, 226, 227, 228,
  500, 501, 502, 504, 505, 507, 508, 509, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559,
  700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 755, 770, 771, 772, 773, 774, 776, 777, 778, 779, 880, 888,
  990, 991, 992, 993, 994, 995, 997, 998, 999,
];
// /prettier-ignore

/**
 * Check for expected format +XXXYYYZZZZZZ: XXX country code + YYY operator code + ZZZZZZ number
 * @param value phone string
 * @returns true | false, whether the input string matched the format
 */
export const validatePhone = (value: string) => {
  if (value.length !== 13) return false;

  const operatorCode = Number(value.substring(4, 7));

  return supportedCodes.includes(operatorCode);
};
