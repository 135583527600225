import React from 'react';
import { DayPicker, DayPickerProps, UI, SelectionState } from 'react-day-picker';
import 'react-day-picker/style.css';
import { useStyletron } from 'styletron-react';
import { styleVars } from '../../../default-theme';

import { ru } from 'react-day-picker/locale';

export function DatePicker(props: DayPickerProps) {
  const [css] = useStyletron();

  const classNames = {
    [UI.CaptionLabel]: css({
      color: styleVars.green,
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '8px',
    }),
    [UI.Chevron]: css({
      fill: styleVars.green,
      width: '16px',
    }),
    [UI.MonthCaption]: css({
      color: styleVars.green,
      fontSize: '16px',
      padding: '18px 20px 8px 20px',
    }),
    [UI.DayButton]: css({
      backgroundColor: 'transparent',
      borderRadius: '10px',
      width: '100%',
      height: '100%',
      border: 'none',
      cursor: 'pointer',
      color: styleVars.secondaryText,
      ':hover': {
        backgroundColor: `${styleVars.green} !important`,
      },
      ':focus': {
        border: `2px solid ${styleVars.green}`,
      },
    }),
    [UI.Day]: css({
      width: '32px',
      borderRadius: '10px',
      height: '32px',
      ':hover': {
        backgroundColor: styleVars.green,
        backdropFilter: 'blur(4px)',
        WebkitBackdropFilter: 'blur(4px)',
      },
      ':focus': {
        border: `2px solid ${styleVars.green}`,
      },
    }),
    [UI.Weekdays]: css({
      color: styleVars.secondaryText,
      height: '32px',
      marginTop: '10px',
    }),
    [UI.Nav]: css({
      position: 'absolute',
      top: '10px',
      right: '7px',
    }),
    [UI.Root]: css({
      backgroundColor: styleVars.bg4,
      borderRadius: '10px',
      width: 'fit-content',
    }),
    [UI.MonthGrid]: css({
      padding: '10px',
    }),
    [UI.Dropdowns]: css({
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
    }),
    [UI.YearsDropdown]: css({
      backgroundColor: styleVars.bg4,
      color: styleVars.secondaryText,
    }),
    [SelectionState.selected]: css({
      backgroundColor: styleVars.green,
      color: 'black !important',
    }),
  };

  return <DayPicker showOutsideDays classNames={classNames} locale={ru} {...props} />;
}
