import spinner from '@/shared/assets/Spinner.gif'; // Временно, уберу когда будет готов лого-лоадер
import { styleVars } from '@/shared/default-theme';
import { Select, SelectOption, SiteLogo } from '@/shared/ui';
import { Button, Textarea } from 'atomize';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styletron-react';
import { Feedback, RejectionReason } from '../model';

interface RejectDriverFormProps {
  title: string;
  reasonOptions: SelectOption<RejectionReason>[];
  loading: boolean;
  onSubmit: (feedback: Feedback) => void;
  onCancel: () => void;
}

export function RejectDriverForm(props: RejectDriverFormProps) {
  const { title, reasonOptions, loading, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const [reason, setReason] = useState<Nullable<SelectOption<RejectionReason>>>(null);
  const [message, setMessage] = useState('');

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (reason) onSubmit({ reason: reason.value, message });
  };

  return (
    <Wrapper>
      <SiteLogo />
      <FormTitle data-testid="test-title">{title}</FormTitle>
      <Form onSubmit={handleSubmit}>
        <FormField>
          <Select
            value={reason}
            options={reasonOptions}
            placeholder={t('feedbackReasonForm_selectReason')}
            onSelect={(value) => setReason(value)}
          />
        </FormField>
        <FormField>
          <MessageTextarea
            placeholder={t('comment')}
            required={reason?.value === RejectionReason.OTHER}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value)}
            data-testid="test-message-textarea"
          />
        </FormField>
        <FlexRow>
          <ActionButton onClick={onCancel} data-testid="test-cancel-button">
            {t('cancel')}
          </ActionButton>
          <ActionButton $bgColor={styleVars.red} disabled={loading} type="submit" data-testid="test-reject-button">
            {loading ? <Spinner src={spinner} alt="spinner" data-testid="test-spinner" /> : t('reject')}
          </ActionButton>
        </FlexRow>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  borderRadius: '20px',
  padding: '24px',
  backgroundColor: styleVars.bg2,
  maxWidth: '454px',
  width: '454px',
});

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const FormTitle = styled('h2', {
  fontWeight: 500,
  fontSize: '20px',
  textAlign: 'center',
  color: 'white',
  marginBottom: '10px',
});

const FormField = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
});

const MessageTextarea = styled(Textarea, {
  backgroundColor: `${styleVars.bg4} !important`,
  border: `1px solid ${styleVars.initialInputBorderColor} !important`,
  outline: 'none',
  color: 'white',
  '::placeholder': {
    fontSize: '14px',
    fontWeight: 400,
  },
});

const ActionButton = styled(Button, (props: { $bgColor?: string }) => ({
  backgroundColor: props.$bgColor || '#FFFFFF1A',
  borderRadius: '10px',
  padding: '6px 14px',
  fontSize: '16px',
  fontWeight: 700,
  color: props.$bgColor ? 'black' : 'white',
  width: '100%',

  display: 'flex',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: props.$bgColor || '#FFFFFF1A',
  },
}));

const FlexRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const Spinner = styled('img', {
  width: '24px',
  height: '24px',
});
