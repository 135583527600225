/* External dependencies */
import { navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { ReactNode, useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';

/* Local dependencies */
import { anonymousClient, setAnonymousClient } from '@/app/clients';
import { getUserRole } from '@/shared/utils';
import { UserRole } from '@/shared/user-roles';
import { useInitClientMutation } from '@/features/login';
import { getDefaultPath } from '@/shared/routing';
import { useAppSelector } from '@/app/store';

export function ApolloWrapper({ children }: { children: ReactNode }) {
  const [initClient, clientState] = useInitClientMutation();
  const { language } = useI18next();
  const currentUser = useAppSelector((s) => s.login.currentUser);

  const { apolloClient } = clientState.data ?? {};

  useEffect(() => {
    if (!currentUser) {
      initClient({});
    }
  }, []);

  // TODO: fix initClient not being able to fullfill the promise
  // if (clientState.isLoading) {
  //   console.log('loading');
  //   return <div>Loading...</div>;
  // }

  if (clientState.isError) {
    console.log('something went wrong');
    return (
      <div>
        Something went wrong!
        {clientState.error as string}
      </div>
    );
  }

  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const currentUserRole = getUserRole(currentUser);

  if (currentUser && pathname === '/login/') {
    navigate(getDefaultPath(currentUserRole));
    return null;
  }

  const userRole = getUserRole(currentUser);

  if (userRole === UserRole.USER) {
    return children;
  }

  // There is a client, meaning login has been established.
  if (currentUser && apolloClient) {
    // If the URL is still login, then navigate to the default page.
    if (pathname.includes('/login')) {
      // Get last left page's pathname from location, "?return=" has 8 characters
      const destinationUrl = decodeURIComponent(location.search).slice(8) || getDefaultPath(currentUserRole);

      navigate(destinationUrl);

      return null;
    }

    // When a user logged in, but want to open main website.
    if (pathname === `/` || pathname === `/${language}/`) {
      return children;
    }

    // If the URL is not login while the user has been authenticated, then render that page directly.
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
  }

  if (!apolloClient) {
    return <ApolloProvider client={anonymousClient || setAnonymousClient()}>{children}</ApolloProvider>;
  }

  // Otherwise, this is the login page, so render it.
  return children;
}
