import React, { FC } from 'react';
import { styled } from 'styletron-react';

interface ContentLayoutProps {
  renderHeader: JSX.Element;
  renderBody: JSX.Element;
}

export const ContentLayout: FC<ContentLayoutProps> = (props) => {
  const { renderHeader, renderBody } = props;
  return (
    <ContentWrapper>
      {renderHeader}
      {renderBody}
    </ContentWrapper>
  );
};

const ContentWrapper = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginTop: '24px',
});
