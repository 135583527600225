import React from 'react';

interface NextIconProps {
  pathColor: string;
  dataTestId?: string;
}

export function NextIcon(props: NextIconProps) {
  const { pathColor, dataTestId = 'test-next-icon' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      data-testid={dataTestId}
    >
      <path
        d="M25.575 24.0003L18.4 31.1753L21.6666 34.5003L32.1666 24.0003L21.6666 13.5003L18.4 16.8253L25.575 24.0003ZM24 47.3337C20.7722 47.3337 17.7388 46.7212 14.9 45.4962C12.0611 44.2712 9.59163 42.6087 7.49163 40.5087C5.39163 38.4087 3.72913 35.9392 2.50413 33.1003C1.27913 30.2614 0.666626 27.2281 0.666626 24.0003C0.666626 20.7725 1.27913 17.7392 2.50413 14.9003C3.72913 12.0614 5.39163 9.59199 7.49163 7.49199C9.59163 5.39199 12.0611 3.72949 14.9 2.50449C17.7388 1.27949 20.7722 0.666992 24 0.666992C27.2277 0.666992 30.2611 1.27949 33.1 2.50449C35.9389 3.72949 38.4083 5.39199 40.5083 7.49199C42.6083 9.59199 44.2708 12.0614 45.4958 14.9003C46.7208 17.7392 47.3333 20.7725 47.3333 24.0003C47.3333 27.2281 46.7208 30.2614 45.4958 33.1003C44.2708 35.9392 42.6083 38.4087 40.5083 40.5087C38.4083 42.6087 35.9389 44.2712 33.1 45.4962C30.2611 46.7212 27.2277 47.3337 24 47.3337Z"
        fill={pathColor}
      />
    </svg>
  );
}
