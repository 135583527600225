import React from 'react';
import { styled } from 'styletron-react';

interface BadgeProps {
  bgColor: string;
  value: string;
}

export function Badge(props: BadgeProps) {
  const { bgColor, value } = props;
  return (
    <BadgeWrapper $bgColor={bgColor} data-testid="test-badge">
      {value}
    </BadgeWrapper>
  );
}

export const BadgeWrapper = styled('div', (props: { $bgColor: string }) => ({
  padding: '2px 6px',
  backgroundColor: props.$bgColor,
  borderRadius: '4px',
  color: 'black',
  width: 'fit-content',
  textAlign: 'center',
  fontSize: '12px',
}));
